import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import PhotoGallery from "../components/PhotoGallery";

const PhotoGalleryPage = ({ data, pageContext }) => {
    const photos = data.albumInfo.images;

    const photosProcessed = photos.map((photo) => {
        const gatsbyImageData = photo.imageName.childImageSharp.gatsbyImageData;
        const { width, height } = gatsbyImageData;
        const aspectRatio = width / height;

        return {
            gatsbyImageData,
            aspectRatio,
            caption: photo.caption,
        };
    });

    return (
        <Layout hasMaxWidth={false}>
            <Helmet titleTemplate="%s Tour de Haft Photos">
                <title>{pageContext.albumYear}</title>
            </Helmet>
            <PhotoGallery photos={photosProcessed} />
        </Layout>
    );
};

export default PhotoGalleryPage;

export const query = graphql`
    query ($directory: String!) {
        albumInfo(mainImage: { relativeDirectory: { eq: $directory } }) {
            images {
                caption
                imageName {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;
