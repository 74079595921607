import * as React from "react";
import PropTypes from "prop-types";
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { chunk, sum } from "lodash";
import { Box } from "rebass";

// import * as styles from "./styles.module.css";

const PhotoGallery = ({ photos }) => {
    const itemsPerRowByBreakpoints = [1, 2, 3, 4];
    const aspectRatios = photos.map((image) => image.aspectRatio);

    const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
        (itemsPerRow) =>
            // Split images into groups of the given size
            chunk(aspectRatios, itemsPerRow).map((rowAspectRatios) =>
                // Sum aspect ratios of images in the given row
                sum(rowAspectRatios)
            )
    );
    return (
        <SRLWrapper>
            {photos.map((image, i) => {
                return (
                    <Box
                        theme={{
                            breakpoints: ["40em", "80em", "120em"],
                        }}
                        key={image.gatsbyImageData.images.fallback.src}
                        sx={{
                            display: "inline-block",
                            transition: "filter 100ms linear",
                            ":hover": {
                                filter: "brightness(90%)",
                                cursor: "pointer",
                            },
                        }}
                        width={rowAspectRatioSumsByBreakpoints.map(
                            // Return a value for each breakpoint
                            (rowAspectRatioSums, j) => {
                                // Find out which row the image is in and get its aspect ratio sum
                                const rowIndex = Math.floor(
                                    i / itemsPerRowByBreakpoints[j]
                                );
                                const rowAspectRatioSum =
                                    rowAspectRatioSums[rowIndex];

                                return `${
                                    (image.aspectRatio / rowAspectRatioSum) *
                                    100
                                }%`;
                            }
                        )}
                    >
                        <GatsbyImage
                            image={getImage(image)}
                            alt={image.caption}
                            srl_gallery_image="true"
                        />
                    </Box>
                );
            })}
        </SRLWrapper>
    );
};

PhotoGallery.propTypes = {
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
            aspectRatio: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default PhotoGallery;
